import { comments } from '@hpx-it/developer-api-client';
import { Ticket } from '@hpx-it/queue-client';
import { CommentsApiContext, QueueApiContext } from 'api';
import { useInterval } from 'hooks';
import { useOptionalTicket } from 'hooks/useTicket';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

type SelectedTicketContextProps = {
  selectedTicket: Ticket | undefined;
  setSelectedTicket: (ticket: Ticket | undefined) => void;
  fetchSelectedTicket: () => Promise<Ticket | undefined>;
  comments: comments.Comments;
  commentsLoading: boolean;
};

const SELECTED_TICKET_POLL_INTERVAL_SECONDS = 5;
const COMMENTS_POLL_INTERVAL_SECONDS = 5;

export type SelectedTicketProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: SelectedTicketContextProps = {
  selectedTicket: undefined,
  setSelectedTicket: () => {},
  fetchSelectedTicket: async () => undefined,
  comments: [],
  commentsLoading: false,
};

export const SelectedTicketContext =
  createContext<SelectedTicketContextProps>(DEFAULT_CONTEXT);

export const SelectedTicketProvider = ({
  children,
}: SelectedTicketProviderProps) => {
  const { getTicket } = useContext(QueueApiContext);
  const { getComments } = useContext(CommentsApiContext);

  const [selectedTicket, setSelectedTicket] = useOptionalTicket();
  const [comments, setComments] = useState<comments.Comments>([]);
  const [commentsLoading, setCommentsLoading] = useState(false);

  const selectedTicketRef = useRef(selectedTicket);

  useEffect(() => {
    selectedTicketRef.current = selectedTicket;
  }, [selectedTicket]);

  const fetchSelectedTicket = useCallback(async () => {
    if (selectedTicket) {
      const ticket = await getTicket(selectedTicket.getTicketId());
      if (ticket && selectedTicketRef.current === selectedTicket) {
        setSelectedTicket(ticket);
      }
      return ticket;
    }
  }, [getTicket, selectedTicket, setSelectedTicket]);

  const fetchCommentsForSelectedTicket = useCallback(async () => {
    if (selectedTicket) {
      const fetchedComments = await getComments(selectedTicket.getTicketId());
      if (fetchedComments && selectedTicketRef.current === selectedTicket) {
        setComments(fetchedComments.comments);
      }
    } else {
      setComments([]);
    }
  }, [getComments, selectedTicket]);

  const fetchCommentsForSelectedTicketWithLoading = useCallback(async () => {
    setCommentsLoading(true);
    try {
      await fetchCommentsForSelectedTicket();
    } finally {
      setCommentsLoading(false);
    }
  }, [fetchCommentsForSelectedTicket]);

  useEffect(() => {
    fetchCommentsForSelectedTicketWithLoading();
  }, [fetchCommentsForSelectedTicketWithLoading, selectedTicket]);

  useInterval(
    fetchSelectedTicket,
    SELECTED_TICKET_POLL_INTERVAL_SECONDS * 1000,
  );
  useInterval(
    fetchCommentsForSelectedTicket,
    COMMENTS_POLL_INTERVAL_SECONDS * 1000,
  );

  return (
    <SelectedTicketContext.Provider
      value={{
        setSelectedTicket,
        selectedTicket,
        fetchSelectedTicket,
        comments,
        commentsLoading,
      }}
    >
      {children}
    </SelectedTicketContext.Provider>
  );
};

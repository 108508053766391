/* istanbul ignore file */
import { setupLogging } from '@hpx-it/react-app';

const {
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_SERVICE_NAME,
  REACT_APP_NODE_ENV,
} = process.env;

setupLogging({
  clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
  site: REACT_APP_DATADOG_SITE,
  service: REACT_APP_SERVICE_NAME,
  env: REACT_APP_NODE_ENV,
});

import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import { logger } from '@hpx-it/react-app';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { AttachmentApiContext } from 'api';
import { TechnicianContext } from 'contexts';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { pushToastMessage } from 'utils';

import { getLoadingGridContainerStyle } from '../style';
import { getMediaType } from '../TicketAttachments';

export const EditImageDialog = ({
  selectedImageId,
  remoteAssistId,
  onClose,
  setAttachmentUrls,
}: {
  selectedImageId: string;
  remoteAssistId: string;
  onClose: () => void;
  setAttachmentUrls: Dispatch<SetStateAction<AttachmentUrl[]>>;
}) => {
  const [localImageUrl, setLocalImageUrl] = useState<string>();
  const [changesMade, setChangesMade] = useState(false);
  const { downloadAttachment, uploadAttachment } =
    useContext(AttachmentApiContext);
  const [attachmentsApiLoading, setAttachmentsApiLoading] = useState(false);
  const { technician } = useContext(TechnicianContext);

  const imageRef = useRef<null | any>();

  useEffect(() => {
    (async () => {
      setLocalImageUrl(
        URL.createObjectURL(await downloadAttachment({ id: selectedImageId })),
      );
    })();
  }, [downloadAttachment, selectedImageId]);

  const editAndSaveScreenshot = async () => {
    const instance = imageRef.current.getInstance();
    const dataURL = instance.toDataURL();
    const response = await fetch(dataURL);
    const blob = await response.blob();
    const file = new File([blob], 'screenshot.jpg', {
      type: 'image/jpeg',
    });
    const input = {
      attached_to_type: 'remote_assist' as const,
      attached_to: remoteAssistId,
      file,
      user_type: 'technician' as const,
      user_id: technician?.id,
    };

    const uploadEditedScreenshot = async () => {
      setAttachmentsApiLoading(true);
      try {
        const uploadedAttachment = await uploadAttachment(input);
        if (uploadedAttachment) {
          setAttachmentUrls((attachmentUrls) => [
            ...attachmentUrls,
            {
              id: uploadedAttachment.id,
              url: URL.createObjectURL(blob),
              mediaType: getMediaType(uploadedAttachment.file_extension),
              extension: uploadedAttachment.file_extension,
            },
          ]);
          pushToastMessage({
            content: 'Edited screenshot saved',
            variant: 'success',
            autohide: 5000,
          });
        }
        onClose();
      } catch (error) {
        logger.error('Failed to save edited screenshot', { message: error });
        pushToastMessage({
          content: 'Unable to save edited screenshot',
          variant: 'error',
          action: {
            text: 'retry',
            function: uploadEditedScreenshot,
          },
        });
      } finally {
        setAttachmentsApiLoading(false);
      }
    };

    uploadEditedScreenshot();
  };

  if (!localImageUrl) {
    return (
      <Grid {...getLoadingGridContainerStyle()}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        height: '100%',

        justifyContent: 'center',
      }}
    >
      <ImageEditor
        key={'img'}
        ref={imageRef}
        includeUI={{
          loadImage: {
            path: localImageUrl,
            name: 'SampleImage',
          },
          theme: {
            'header.display': 'none',
          },
          menu: ['crop', 'rotate', 'draw', 'shape', 'text'],
          uiSize: {
            width: '1000px',
            height: '100%',
          },
          menuBarPosition: 'bottom',
        }}
        cssMaxHeight={400}
        cssMaxWidth={600}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        onUndoStackChanged={(length: number) => {
          setChangesMade(length !== 0);
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => editAndSaveScreenshot()}
        disabled={attachmentsApiLoading || !changesMade}
        sx={{
          position: 'absolute',
          margin: '15px',
          bottom: '0',
          right: '0',
          zIndex: 5,
        }}
      >
        SAVE
      </Button>
    </Box>
  );
};

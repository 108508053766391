import { Grid, Typography } from '@mui/material';
import { useTimer } from 'react-timer-hook';

import { formatTimers } from './formatTimers';
import { getTimerGridStyle, getTimerTypographyStyle } from './style';

type CountDownTimerProps = {
  endTime: string;
};

export function useCountDownTimer({ endTime }: CountDownTimerProps) {
  const { minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(endTime).getTime(),
  });

  return formatTimers({ days, hours, minutes });
}

type CountdownTimerProps = {
  timeoutAt: string;
};

export function CountdownTimer({ timeoutAt }: CountdownTimerProps) {
  const timerString = useCountDownTimer({ endTime: timeoutAt });

  return (
    <Grid container {...getTimerGridStyle()}>
      <Grid item>
        <Typography {...getTimerTypographyStyle()}>{timerString}</Typography>
      </Grid>
    </Grid>
  );
}

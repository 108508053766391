import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import Carousel from 'react-material-ui-carousel';

import { EditImageDialog } from './EditImageDialog';
import {
  TicketAttachmentCarouseDialoglIFrameStyle,
  TicketAttachmentCarouselDialogDivStyle,
  TicketAttachmentCarouselDialogDivVideoStyle,
  TicketImageCarouselDialogStyle,
  carouselImageDialogContentStyle,
  carouselImageDialogIconButtonStyle,
  carouselImageDialogPaperStyle,
} from './style';

export const TicketImageCarouselDialog = ({
  attachmentUrls,
  selectedImage,
  remoteAssistId,
  onClose,
  setAttachmentUrls,
  setSelectedImage,
}: {
  attachmentUrls: AttachmentUrl[];
  selectedImage: AttachmentUrl;
  remoteAssistId: string;
  onClose: () => void;
  setAttachmentUrls: Dispatch<SetStateAction<AttachmentUrl[]>>;
  setSelectedImage: (attachmentUrl: AttachmentUrl | undefined) => void;
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const currentIndex = attachmentUrls.findIndex(
        (attachment) => attachment.url === selectedImage.url,
      );

      const direction =
        event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0;

      setSelectedImage(
        attachmentUrls[
          (currentIndex + direction + attachmentUrls.length) %
            attachmentUrls.length
        ],
      );

      if (event.key === 'Escape') {
        // Close the dialog
        onClose();
      }
    },
    [attachmentUrls, setSelectedImage, selectedImage.url, onClose],
  );
  return (
    <Dialog
      open={!!selectedImage}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: carouselImageDialogPaperStyle,
      }}
      onKeyDown={handleKeyDown}
    >
      <IconButton onClick={onClose} sx={carouselImageDialogIconButtonStyle}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={carouselImageDialogContentStyle}>
        <Carousel
          swipe={false}
          autoPlay={false}
          index={attachmentUrls.findIndex(
            (attachment) => attachment.url === selectedImage.url,
          )}
          height="100%"
          navButtonsAlwaysVisible={true}
          sx={TicketImageCarouselDialogStyle}
        >
          {attachmentUrls.map((attachment, index) => {
            return (
              <div key={index} style={TicketAttachmentCarouselDialogDivStyle}>
                {attachment.mediaType === 'video' ? (
                  <video
                    src={attachment.url}
                    controls
                    style={TicketAttachmentCarouselDialogDivVideoStyle}
                  />
                ) : attachment.mediaType === 'image' ? (
                  <EditImageDialog
                    selectedImageId={attachment.id}
                    remoteAssistId={remoteAssistId}
                    onClose={onClose}
                    setAttachmentUrls={setAttachmentUrls}
                  />
                ) : (
                  <iframe
                    src={attachment.url}
                    title={attachment.url}
                    style={TicketAttachmentCarouseDialoglIFrameStyle}
                  ></iframe>
                )}
              </div>
            );
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

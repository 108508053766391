/**
 * DO NOT EDIT THIS FILE
 * These types are generated automatically. All edits will be overwritten.
 * To update these types, run `npm run graphql:codegen`.
 */
/* eslint-disable @typescript-eslint/naming-convention */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
}

export interface Attendee {
  demandClientId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  serviceLocation?: Maybe<Scalars['String']>;
  supplyClientId: Scalars['String'];
}

export interface AttendeeValidationField {
  __typename?: 'AttendeeValidationField';
  error?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
}

export interface AttendeeValidationResponse {
  __typename?: 'AttendeeValidationResponse';
  email?: Maybe<AttendeeValidationField>;
  firstName?: Maybe<AttendeeValidationField>;
  lastName?: Maybe<AttendeeValidationField>;
  phone?: Maybe<AttendeeValidationField>;
  serviceLocation?: Maybe<AttendeeValidationField>;
  valid: Scalars['Boolean'];
}

export enum AuthRole {
  TECHNICIAN = 'TECHNICIAN',
}

export interface CancelRemoteAssistResult extends MutationResponse {
  __typename?: 'CancelRemoteAssistResult';
  error?: Maybe<Error>;
  remoteAssist?: Maybe<RemoteAssist>;
  success: Scalars['Boolean'];
}

export interface Channels {
  __typename?: 'Channels';
  video: VideoStatus;
}

export interface Client {
  __typename?: 'Client';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  relations: Array<ClientRelation>;
  scripts: Array<ClientScripts>;
  supply: Array<Scalars['ID']>;
  updatedAt: Scalars['String'];
}

/**
 * Define RemoteAssist related Graphql types, enums,
 * inputs, mutations in this file that will
 * be used by the frontend, `fieldguide`.
 *
 * Run `npm run generate`
 * to update or create ./src/types/schema.ts
 * where the types can be used throughout
 */
export interface ClientInfo {
  __typename?: 'ClientInfo';
  body: Scalars['String'];
  heading: Scalars['String'];
  order: Scalars['Int'];
}

export enum ClientRelation {
  CURRENT_CLIENT = 'CURRENT_CLIENT',
  CURRENT_CLIENT_DEMAND = 'CURRENT_CLIENT_DEMAND',
  CURRENT_CLIENT_SUPPLY = 'CURRENT_CLIENT_SUPPLY',
  POSSIBLE_CLIENT = 'POSSIBLE_CLIENT',
}

export interface ClientScript {
  __typename?: 'ClientScript';
  body: Scalars['String'];
  heading: Scalars['String'];
  order: Scalars['Int'];
}

export interface ClientScripts {
  __typename?: 'ClientScripts';
  key: Scalars['String'];
  script: Array<ClientScript>;
}

export interface ClientTrade {
  __typename?: 'ClientTrade';
  clientId: Scalars['ID'];
  tradeId: Scalars['ID'];
}

export interface CompleteRemoteAssistResult extends MutationResponse {
  __typename?: 'CompleteRemoteAssistResult';
  error?: Maybe<Error>;
  remoteAssist?: Maybe<RemoteAssist>;
  success: Scalars['Boolean'];
}

export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
}

export interface DateRangeFilter {
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
}

export interface Diagnosis {
  __typename?: 'Diagnosis';
  createdAt: Scalars['DateTime'];
  equipment: Array<Equipment>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface DiagnosisCreateInput {
  equipment: Array<EquipmentInput>;
}

export interface DiagnosisResult extends MutationResponse {
  __typename?: 'DiagnosisResult';
  diagnosis?: Maybe<Diagnosis>;
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
}

export interface DiagnosisUpdateInput {
  diagnosisId: Scalars['String'];
  equipment: Array<EquipmentInput>;
}

export interface Email {
  __typename?: 'Email';
  address: Scalars['String'];
}

export interface Equipment {
  __typename?: 'Equipment';
  brand?: Maybe<Scalars['String']>;
  equipmentNotes?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface EquipmentInput {
  brand?: Maybe<Scalars['String']>;
  equipmentNotes?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface EquipmentSearchResponse {
  __typename?: 'EquipmentSearchResponse';
  searchResults?: Maybe<Array<Scalars['String']>>;
}

export interface Error {
  __typename?: 'Error';
  code: Scalars['String'];
  message: Scalars['String'];
}

export interface Mutation {
  __typename?: 'Mutation';
  cancelRemoteAssist: CancelRemoteAssistResult;
  completeRemoteAssist: CompleteRemoteAssistResult;
  sendSMSInvite: SendSmsInviteResult;
  updateRemoteAssist: UpdateRemoteAssistResult;
  updateTechnicianStatus: TechnicianUpdateStatusResult;
  verifyAttendee: AttendeeValidationResponse;
}

export interface MutationCancelRemoteAssistArgs {
  id: Scalars['ID'];
  remoteAssistCancelInput: RemoteAssistCancelInput;
}

export interface MutationCompleteRemoteAssistArgs {
  id: Scalars['ID'];
  remoteAssistCompleteInput: RemoteAssistCompleteInput;
}

export interface MutationSendSmsInviteArgs {
  phoneNumber?: Maybe<Scalars['String']>;
  remoteAssistId: Scalars['ID'];
}

export interface MutationUpdateRemoteAssistArgs {
  id: Scalars['ID'];
  remoteAssistUpdateInput: RemoteAssistUpdateInput;
}

export interface MutationUpdateTechnicianStatusArgs {
  status: TechnicianStatus;
}

export interface MutationVerifyAttendeeArgs {
  attendee: Attendee;
}

export interface MutationResponse {
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
}

export interface OnDemand {
  __typename?: 'OnDemand';
  estimatedWaitTime: Scalars['Float'];
}

export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PagedClientTrades extends PagedResponse {
  __typename?: 'PagedClientTrades';
  data: Array<ClientTrade>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedClients extends PagedResponse {
  __typename?: 'PagedClients';
  data: Array<Client>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedRemoteAssist extends RemoteAssistBase {
  __typename?: 'PagedRemoteAssist';
  affiliateKey?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  canceledReason?: Maybe<RemoteAssistCanceledReason>;
  clientId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  customer: RemoteAssistCustomer;
  demandClientId: Scalars['ID'];
  diagnosis?: Maybe<Diagnosis>;
  eligibleSupply?: Maybe<RemoteAssistEligibleSupply>;
  estimatedDuration: Scalars['Float'];
  id: Scalars['ID'];
  invoice?: Maybe<RemoteAssistInvoice>;
  lastStatusChangeAt: Scalars['DateTime'];
  onDemand?: Maybe<OnDemand>;
  parentRelation?: Maybe<RemoteAssistRelation>;
  portalStatus?: Maybe<RemoteAssistPortalStatus>;
  reference?: Maybe<Scalars['String']>;
  reviewed: Scalars['Boolean'];
  scheduled?: Maybe<Scheduled>;
  serviceCodeId: Scalars['ID'];
  status: RemoteAssistStatus;
  supplyClientId: Scalars['ID'];
  technician?: Maybe<RemoteAssistTechnician>;
  title?: Maybe<Scalars['String']>;
  tradeId: Scalars['ID'];
  type: RemoteAssistType;
  updatedAt: Scalars['DateTime'];
}

export interface PagedRemoteAssists extends PagedResponse {
  __typename?: 'PagedRemoteAssists';
  data: Array<PagedRemoteAssist>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedResponse {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedServiceCodes extends PagedResponse {
  __typename?: 'PagedServiceCodes';
  data: Array<ServiceCode>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedTechnicians extends PagedResponse {
  __typename?: 'PagedTechnicians';
  data: Array<Technician>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface PagedTrades extends PagedResponse {
  __typename?: 'PagedTrades';
  data: Array<Trade>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
}

export interface Phone {
  __typename?: 'Phone';
  number: Scalars['String'];
}

export enum PriceType {
  CLIENT = 'CLIENT',
  CUSTOMER = 'CUSTOMER',
}

export interface ProductOutcomesInput {
  clientId: Scalars['ID'];
  isMember?: Maybe<Scalars['Boolean']>;
  productType: Scalars['String'];
}

export interface ProductTypesInput {
  clientId: Scalars['ID'];
  isMember?: Maybe<Scalars['Boolean']>;
}

export interface PromisedWindow {
  __typename?: 'PromisedWindow';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
}

export interface Query {
  __typename?: 'Query';
  brands?: Maybe<EquipmentSearchResponse>;
  clientServiceCodes: PagedServiceCodes;
  clientTrades: PagedClientTrades;
  clients: Array<Client>;
  equipment?: Maybe<EquipmentSearchResponse>;
  productOutcomes: Array<Scalars['String']>;
  productTypes: Array<Scalars['String']>;
  quote: Quote;
  remoteAssist?: Maybe<RemoteAssist>;
  remoteAssists?: Maybe<PagedRemoteAssists>;
  serviceCodes: PagedServiceCodes;
  technician?: Maybe<Technician>;
  technicians: Array<Technician>;
  trades: PagedTrades;
}

export interface QueryBrandsArgs {
  equipmentType: Scalars['String'];
}

export interface QueryClientServiceCodesArgs {
  clientId: Scalars['ID'];
}

export interface QueryClientTradesArgs {
  clientId: Scalars['ID'];
}

export interface QueryProductOutcomesArgs {
  input: ProductOutcomesInput;
}

export interface QueryProductTypesArgs {
  input: ProductTypesInput;
}

export interface QueryQuoteArgs {
  input: QuoteInput;
}

export interface QueryRemoteAssistArgs {
  id: Scalars['ID'];
}

export interface QueryRemoteAssistsArgs {
  input?: Maybe<RemoteAssistsInput>;
}

export interface QueryServiceCodesArgs {
  input?: Maybe<ServiceCodesInput>;
}

export interface QueryTechniciansArgs {
  input?: Maybe<TechniciansInput>;
}

export interface Quote {
  __typename?: 'Quote';
  amount?: Maybe<Scalars['Float']>;
  currency: Currency;
  maxAmount?: Maybe<Scalars['Float']>;
  minAmount?: Maybe<Scalars['Float']>;
}

export interface QuoteInput {
  clientId: Scalars['String'];
  isMember?: Maybe<Scalars['Boolean']>;
  productOutcome?: Maybe<Scalars['String']>;
  productType: Scalars['String'];
}

export interface RemoteAssist extends RemoteAssistBase {
  __typename?: 'RemoteAssist';
  affiliateKey?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  canceledReason?: Maybe<RemoteAssistCanceledReason>;
  children: Array<PagedRemoteAssist>;
  clientId: Scalars['ID'];
  clientInfo: Array<ClientInfo>;
  createdAt: Scalars['DateTime'];
  customer: RemoteAssistCustomer;
  demandClientId: Scalars['ID'];
  diagnosis?: Maybe<Diagnosis>;
  eligibleSupply?: Maybe<RemoteAssistEligibleSupply>;
  estimatedDuration: Scalars['Float'];
  id: Scalars['ID'];
  invoice?: Maybe<RemoteAssistInvoice>;
  lastStatusChangeAt: Scalars['DateTime'];
  onDemand?: Maybe<OnDemand>;
  parent?: Maybe<RemoteAssist>;
  parentRelation?: Maybe<RemoteAssistRelation>;
  portalStatus?: Maybe<RemoteAssistPortalStatus>;
  recordingURLs?: Maybe<Array<Scalars['String']>>;
  reference?: Maybe<Scalars['String']>;
  reviewed: Scalars['Boolean'];
  scheduled?: Maybe<Scheduled>;
  serviceCodeId: Scalars['ID'];
  status: RemoteAssistStatus;
  supplyClientId: Scalars['ID'];
  technician?: Maybe<RemoteAssistTechnician>;
  title?: Maybe<Scalars['String']>;
  tradeId: Scalars['ID'];
  type: RemoteAssistType;
  updatedAt: Scalars['DateTime'];
}

export interface RemoteAssistBase {
  affiliateKey?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  canceledReason?: Maybe<RemoteAssistCanceledReason>;
  clientId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  customer: RemoteAssistCustomer;
  demandClientId: Scalars['ID'];
  diagnosis?: Maybe<Diagnosis>;
  eligibleSupply?: Maybe<RemoteAssistEligibleSupply>;
  estimatedDuration: Scalars['Float'];
  id: Scalars['ID'];
  invoice?: Maybe<RemoteAssistInvoice>;
  lastStatusChangeAt: Scalars['DateTime'];
  onDemand?: Maybe<OnDemand>;
  parentRelation?: Maybe<RemoteAssistRelation>;
  portalStatus?: Maybe<RemoteAssistPortalStatus>;
  reference?: Maybe<Scalars['String']>;
  reviewed: Scalars['Boolean'];
  scheduled?: Maybe<Scheduled>;
  serviceCodeId: Scalars['ID'];
  status: RemoteAssistStatus;
  supplyClientId: Scalars['ID'];
  technician?: Maybe<RemoteAssistTechnician>;
  title?: Maybe<Scalars['String']>;
  tradeId: Scalars['ID'];
  type: RemoteAssistType;
  updatedAt: Scalars['DateTime'];
}

export interface RemoteAssistCancelInput {
  canceledReason: RemoteAssistCanceledReasonInput;
}

export enum RemoteAssistCanceledReason {
  CANCELED = 'CANCELED',
  CANCELED_BY_AGENT = 'CANCELED_BY_AGENT',
  ERROR = 'ERROR',
  RESCHEDULED = 'RESCHEDULED',
  TIMEOUT = 'TIMEOUT',
}

export enum RemoteAssistCanceledReasonInput {
  CANCELED = 'CANCELED',
  CANCELED_BY_AGENT = 'CANCELED_BY_AGENT',
  RESCHEDULED = 'RESCHEDULED',
}

export interface RemoteAssistCompleteDiagnosisInput {
  diagnosisId?: Maybe<Scalars['String']>;
  equipment: Array<EquipmentInput>;
}

export interface RemoteAssistCompleteInput {
  customerIsMember?: Maybe<Scalars['Boolean']>;
  diagnosis?: Maybe<RemoteAssistCompleteDiagnosisInput>;
  invoice?: Maybe<RemoteAssistInvoiceUpdate>;
  reference?: Maybe<Scalars['String']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  technicianNotes?: Maybe<Scalars['String']>;
}

export interface RemoteAssistCustomer {
  __typename?: 'RemoteAssistCustomer';
  email?: Maybe<Email>;
  firstName?: Maybe<Scalars['String']>;
  isMember: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Phone>;
  postalCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
}

export interface RemoteAssistCustomerUpdate {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
}

export interface RemoteAssistDiagnosisUpdate {
  equipment?: Maybe<Array<EquipmentInput>>;
}

export interface RemoteAssistEligibleSupply {
  __typename?: 'RemoteAssistEligibleSupply';
  technicianIds: Array<Scalars['String']>;
}

export interface RemoteAssistInvoice {
  __typename?: 'RemoteAssistInvoice';
  billableVideoMinutes?: Maybe<Scalars['Int']>;
  clientPrice: Scalars['Float'];
  customerPrice: Scalars['Float'];
  productOutcome?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['ID']>;
}

export interface RemoteAssistInvoiceUpdate {
  productOutcome?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
}

export interface RemoteAssistParent {
  __typename?: 'RemoteAssistParent';
  id: Scalars['String'];
  relation: RemoteAssistRelation;
}

export enum RemoteAssistPortalStatus {
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  RESCHEDULED = 'RESCHEDULED',
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
}

export enum RemoteAssistRelation {
  CUSTOMER_CALLBACK = 'CUSTOMER_CALLBACK',
  FUTURE_FOLLOW_UP = 'FUTURE_FOLLOW_UP',
  RESCHEDULE = 'RESCHEDULE',
  TRANSFER = 'TRANSFER',
}

export interface RemoteAssistStartFollowUpInput {
  parentId: Scalars['ID'];
}

export enum RemoteAssistStatus {
  ASSIGNED = 'ASSIGNED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  RESERVED = 'RESERVED',
  SCHEDULED = 'SCHEDULED',
  WRAPPING = 'WRAPPING',
}

export interface RemoteAssistTechnician {
  __typename?: 'RemoteAssistTechnician';
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
}

export interface RemoteAssistTechnicianUpdate {
  notes?: Maybe<Scalars['String']>;
}

export enum RemoteAssistType {
  ON_DEMAND = 'ON_DEMAND',
  SCHEDULED = 'SCHEDULED',
}

export interface RemoteAssistUpdateInput {
  customer?: Maybe<RemoteAssistCustomerUpdate>;
  diagnosis?: Maybe<RemoteAssistDiagnosisUpdate>;
  invoice?: Maybe<RemoteAssistInvoiceUpdate>;
  notes?: Maybe<Scalars['String']>;
  reviewed?: Maybe<Scalars['Boolean']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  starred?: Maybe<Scalars['Boolean']>;
  technician?: Maybe<RemoteAssistTechnicianUpdate>;
}

export interface RemoteAssistsInput {
  assignedAtRange?: Maybe<DateRangeFilter>;
  clientId?: Maybe<Scalars['ID']>;
  lastStatusChangeAtRange?: Maybe<DateRangeFilter>;
  orderBy?: Maybe<RemoteAssistsOrderBy>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  portalStatus?: Maybe<RemoteAssistPortalStatus>;
  reviewed?: Maybe<Scalars['Boolean']>;
  scheduledStartAtRange?: Maybe<DateRangeFilter>;
  showAllForClient?: Maybe<Scalars['Boolean']>;
  showEligibleSupply?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<RemoteAssistStatus>>;
  supplyClientId?: Maybe<Scalars['ID']>;
  technicianId?: Maybe<Scalars['ID']>;
  tradeId?: Maybe<Scalars['ID']>;
}

export interface RemoteAssistsOrderBy {
  direction: OrderByDirection;
  field: RemoteAssistsOrderByField;
}

export enum RemoteAssistsOrderByField {
  ASSIGNED_AT = 'ASSIGNED_AT',
  CREATED_AT = 'CREATED_AT',
  LAST_STATUS_CHANGE_AT = 'LAST_STATUS_CHANGE_AT',
  SCHEDULED_START_AT = 'SCHEDULED_START_AT',
}

export interface Scheduled {
  __typename?: 'Scheduled';
  promisedWindow: PromisedWindow;
}

export interface SendSmsInviteResult extends MutationResponse {
  __typename?: 'SendSMSInviteResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
}

export interface ServiceCode {
  __typename?: 'ServiceCode';
  createdAt: Scalars['DateTime'];
  estimatedDuration: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  tradeId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
}

export interface ServiceCodesInput {
  id?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['ID']>;
}

export interface Technician {
  __typename?: 'Technician';
  channelStatuses: Channels;
  clientId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  email: Email;
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceCodes: Array<Scalars['String']>;
  status: TechnicianStatus;
  trades: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
}

export enum TechnicianStatus {
  AVAILABLE = 'AVAILABLE',
  OFFLINE = 'OFFLINE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface TechnicianUpdateStatusResult extends MutationResponse {
  __typename?: 'TechnicianUpdateStatusResult';
  error?: Maybe<Error>;
  success: Scalars['Boolean'];
  technician?: Maybe<Technician>;
}

export interface TechniciansInput {
  showArchived?: Maybe<Scalars['Boolean']>;
  userClientOnly?: Maybe<Scalars['Boolean']>;
}

export interface TechniciansUpdateInput {
  ids: Array<Scalars['ID']>;
}

export interface Trade {
  __typename?: 'Trade';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface UpdateRemoteAssistResult extends MutationResponse {
  __typename?: 'UpdateRemoteAssistResult';
  error?: Maybe<Error>;
  remoteAssist?: Maybe<RemoteAssist>;
  success: Scalars['Boolean'];
}

export enum VideoStatus {
  AVAILABLE = 'AVAILABLE',
  ERROR = 'ERROR',
  ON_CALL = 'ON_CALL',
  TASK_PENDING = 'TASK_PENDING',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type ChildrenRemoteAssistPropsFragment = {
  __typename?: 'PagedRemoteAssist';
  id: string;
  parentRelation?: Maybe<RemoteAssistRelation>;
  createdAt: string;
  assignedAt?: Maybe<string>;
  status: RemoteAssistStatus;
  canceledReason?: Maybe<RemoteAssistCanceledReason>;
  scheduled?: Maybe<{
    __typename?: 'Scheduled';
    promisedWindow: { __typename?: 'PromisedWindow'; start: string };
  }>;
};

export type ParentRemoteAssistPropsFragment = {
  __typename?: 'RemoteAssist';
  id: string;
  parentRelation?: Maybe<RemoteAssistRelation>;
  createdAt: string;
  assignedAt?: Maybe<string>;
  status: RemoteAssistStatus;
  canceledReason?: Maybe<RemoteAssistCanceledReason>;
  scheduled?: Maybe<{
    __typename?: 'Scheduled';
    promisedWindow: { __typename?: 'PromisedWindow'; start: string };
  }>;
};

export type CompleteRemoteAssistMutationVariables = Exact<{
  remoteAssistCompleteInput: RemoteAssistCompleteInput;
  id: Scalars['ID'];
}>;

export type CompleteRemoteAssistMutation = {
  __typename?: 'Mutation';
  completeRemoteAssist: {
    __typename?: 'CompleteRemoteAssistResult';
    remoteAssist?: Maybe<{
      __typename?: 'RemoteAssist';
      id: string;
      status: RemoteAssistStatus;
    }>;
  };
};

export type SendSmsInviteMutationVariables = Exact<{
  remoteAssistId: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
}>;

export type SendSmsInviteMutation = {
  __typename?: 'Mutation';
  sendSMSInvite: {
    __typename?: 'SendSMSInviteResult';
    success: boolean;
    error?: Maybe<{ __typename?: 'Error'; message: string; code: string }>;
  };
};

export type UpdateRemoteAssistCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  remoteAssistUpdateInput: RemoteAssistUpdateInput;
}>;

export type UpdateRemoteAssistCustomerMutation = {
  __typename?: 'Mutation';
  updateRemoteAssist: {
    __typename?: 'UpdateRemoteAssistResult';
    success: boolean;
    remoteAssist?: Maybe<{
      __typename?: 'RemoteAssist';
      id: string;
      customer: {
        __typename?: 'RemoteAssistCustomer';
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        postalCode?: Maybe<string>;
        notes?: Maybe<string>;
        email?: Maybe<{ __typename?: 'Email'; address: string }>;
        phone?: Maybe<{ __typename?: 'Phone'; number: string }>;
      };
    }>;
  };
};

export type UpdateRemoteAssistEligibleSupplyMutationVariables = Exact<{
  id: Scalars['ID'];
  remoteAssistUpdateInput: RemoteAssistUpdateInput;
}>;

export type UpdateRemoteAssistEligibleSupplyMutation = {
  __typename?: 'Mutation';
  updateRemoteAssist: {
    __typename?: 'UpdateRemoteAssistResult';
    success: boolean;
    remoteAssist?: Maybe<{
      __typename?: 'RemoteAssist';
      id: string;
      eligibleSupply?: Maybe<{
        __typename?: 'RemoteAssistEligibleSupply';
        technicianIds: Array<string>;
      }>;
    }>;
  };
};

export type UpdateRemoteAssistReviewedMutationVariables = Exact<{
  id: Scalars['ID'];
  remoteAssistUpdateInput: RemoteAssistUpdateInput;
}>;

export type UpdateRemoteAssistReviewedMutation = {
  __typename?: 'Mutation';
  updateRemoteAssist: {
    __typename?: 'UpdateRemoteAssistResult';
    success: boolean;
    remoteAssist?: Maybe<{
      __typename?: 'RemoteAssist';
      id: string;
      reviewed: boolean;
    }>;
  };
};

export type UpdateTechnicianStatusMutationVariables = Exact<{
  status: TechnicianStatus;
}>;

export type UpdateTechnicianStatusMutation = {
  __typename?: 'Mutation';
  updateTechnicianStatus: {
    __typename?: 'TechnicianUpdateStatusResult';
    technician?: Maybe<{
      __typename?: 'Technician';
      id: string;
      status: TechnicianStatus;
    }>;
  };
};

export type VerifyAttendeeMutationVariables = Exact<{
  attendee: Attendee;
}>;

export type VerifyAttendeeMutation = {
  __typename?: 'Mutation';
  verifyAttendee: {
    __typename?: 'AttendeeValidationResponse';
    valid: boolean;
    phone?: Maybe<{
      __typename?: 'AttendeeValidationField';
      newValue?: Maybe<string>;
      error?: Maybe<string>;
      warning?: Maybe<string>;
    }>;
    email?: Maybe<{
      __typename?: 'AttendeeValidationField';
      newValue?: Maybe<string>;
      error?: Maybe<string>;
      warning?: Maybe<string>;
    }>;
    serviceLocation?: Maybe<{
      __typename?: 'AttendeeValidationField';
      newValue?: Maybe<string>;
      error?: Maybe<string>;
      warning?: Maybe<string>;
    }>;
    firstName?: Maybe<{
      __typename?: 'AttendeeValidationField';
      newValue?: Maybe<string>;
      error?: Maybe<string>;
      warning?: Maybe<string>;
    }>;
    lastName?: Maybe<{
      __typename?: 'AttendeeValidationField';
      newValue?: Maybe<string>;
      error?: Maybe<string>;
      warning?: Maybe<string>;
    }>;
  };
};

export type ClientServiceCodesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClientServiceCodesQuery = {
  __typename?: 'Query';
  clientServiceCodes: {
    __typename?: 'PagedServiceCodes';
    data: Array<{ __typename?: 'ServiceCode'; name: string; id: string }>;
  };
};

export type ClientsQueryVariables = Exact<{ [key: string]: never }>;

export type ClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    name: string;
    relations: Array<ClientRelation>;
    supply: Array<string>;
  }>;
};

export type ProductOutcomesQueryVariables = Exact<{
  input: ProductOutcomesInput;
}>;

export type ProductOutcomesQuery = {
  __typename?: 'Query';
  productOutcomes: Array<string>;
};

export type ProductTypesQueryVariables = Exact<{
  input: ProductTypesInput;
}>;

export type ProductTypesQuery = {
  __typename?: 'Query';
  productTypes: Array<string>;
};

export type RemoteAssistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoteAssistQuery = {
  __typename?: 'Query';
  remoteAssist?: Maybe<{
    __typename?: 'RemoteAssist';
    id: string;
    reference?: Maybe<string>;
    affiliateKey?: Maybe<string>;
    demandClientId: string;
    clientId: string;
    supplyClientId: string;
    tradeId: string;
    serviceCodeId: string;
    title?: Maybe<string>;
    type: RemoteAssistType;
    status: RemoteAssistStatus;
    lastStatusChangeAt: string;
    createdAt: string;
    portalStatus?: Maybe<RemoteAssistPortalStatus>;
    assignedAt?: Maybe<string>;
    clientInfo: Array<{
      __typename?: 'ClientInfo';
      heading: string;
      body: string;
      order: number;
    }>;
    diagnosis?: Maybe<{
      __typename?: 'Diagnosis';
      id: string;
      createdAt: string;
      updatedAt: string;
      equipment: Array<{
        __typename?: 'Equipment';
        name?: Maybe<string>;
        type?: Maybe<string>;
        brand?: Maybe<string>;
        reference?: Maybe<string>;
        modelNumber?: Maybe<string>;
        serialNumber?: Maybe<string>;
        errorCode?: Maybe<string>;
        equipmentNotes?: Maybe<string>;
      }>;
    }>;
    customer: {
      __typename?: 'RemoteAssistCustomer';
      reference?: Maybe<string>;
      firstName?: Maybe<string>;
      lastName?: Maybe<string>;
      postalCode?: Maybe<string>;
      notes?: Maybe<string>;
      isMember: boolean;
      email?: Maybe<{ __typename?: 'Email'; address: string }>;
      phone?: Maybe<{ __typename?: 'Phone'; number: string }>;
    };
    scheduled?: Maybe<{
      __typename?: 'Scheduled';
      promisedWindow: {
        __typename?: 'PromisedWindow';
        start: string;
        end: string;
      };
    }>;
    invoice?: Maybe<{
      __typename?: 'RemoteAssistInvoice';
      productType?: Maybe<string>;
      productOutcome?: Maybe<string>;
      customerPrice: number;
    }>;
    eligibleSupply?: Maybe<{
      __typename?: 'RemoteAssistEligibleSupply';
      technicianIds: Array<string>;
    }>;
    parent?: Maybe<{ __typename?: 'RemoteAssist'; id: string }>;
  }>;
};

export type RemoteAssistForDashboardQueryVariables = Exact<{
  input: RemoteAssistsInput;
}>;

export type RemoteAssistForDashboardQuery = {
  __typename?: 'Query';
  remoteAssists?: Maybe<{
    __typename?: 'PagedRemoteAssists';
    page: number;
    perPage: number;
    totalCount: number;
    data: Array<{
      __typename?: 'PagedRemoteAssist';
      clientId: string;
      reference?: Maybe<string>;
      supplyClientId: string;
      id: string;
      createdAt: string;
      assignedAt?: Maybe<string>;
      tradeId: string;
      portalStatus?: Maybe<RemoteAssistPortalStatus>;
      reviewed: boolean;
      customer: {
        __typename?: 'RemoteAssistCustomer';
        reference?: Maybe<string>;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        postalCode?: Maybe<string>;
        phone?: Maybe<{ __typename?: 'Phone'; number: string }>;
      };
      technician?: Maybe<{ __typename?: 'RemoteAssistTechnician'; id: string }>;
      scheduled?: Maybe<{
        __typename?: 'Scheduled';
        promisedWindow: {
          __typename?: 'PromisedWindow';
          start: string;
          end: string;
        };
      }>;
    }>;
  }>;
};

export type RemoteAssistForPastRaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoteAssistForPastRaQuery = {
  __typename?: 'Query';
  remoteAssist?: Maybe<{
    __typename?: 'RemoteAssist';
    id: string;
    reference?: Maybe<string>;
    supplyClientId: string;
    affiliateKey?: Maybe<string>;
    demandClientId: string;
    tradeId: string;
    serviceCodeId: string;
    type: RemoteAssistType;
    status: RemoteAssistStatus;
    assignedAt?: Maybe<string>;
    lastStatusChangeAt: string;
    recordingURLs?: Maybe<Array<string>>;
    createdAt: string;
    canceledReason?: Maybe<RemoteAssistCanceledReason>;
    parentRelation?: Maybe<RemoteAssistRelation>;
    customer: {
      __typename?: 'RemoteAssistCustomer';
      reference?: Maybe<string>;
      firstName?: Maybe<string>;
      lastName?: Maybe<string>;
      postalCode?: Maybe<string>;
      notes?: Maybe<string>;
      isMember: boolean;
      email?: Maybe<{ __typename?: 'Email'; address: string }>;
      phone?: Maybe<{ __typename?: 'Phone'; number: string }>;
    };
    diagnosis?: Maybe<{
      __typename?: 'Diagnosis';
      id: string;
      equipment: Array<{
        __typename?: 'Equipment';
        name?: Maybe<string>;
        type?: Maybe<string>;
        brand?: Maybe<string>;
        reference?: Maybe<string>;
        modelNumber?: Maybe<string>;
        serialNumber?: Maybe<string>;
        errorCode?: Maybe<string>;
        equipmentNotes?: Maybe<string>;
      }>;
    }>;
    technician?: Maybe<{
      __typename?: 'RemoteAssistTechnician';
      id: string;
      notes?: Maybe<string>;
    }>;
    scheduled?: Maybe<{
      __typename?: 'Scheduled';
      promisedWindow: {
        __typename?: 'PromisedWindow';
        start: string;
        end: string;
      };
    }>;
    invoice?: Maybe<{
      __typename?: 'RemoteAssistInvoice';
      productType?: Maybe<string>;
      productOutcome?: Maybe<string>;
      customerPrice: number;
      billableVideoMinutes?: Maybe<number>;
    }>;
    parent?: Maybe<{
      __typename?: 'RemoteAssist';
      id: string;
      parentRelation?: Maybe<RemoteAssistRelation>;
      createdAt: string;
      assignedAt?: Maybe<string>;
      status: RemoteAssistStatus;
      canceledReason?: Maybe<RemoteAssistCanceledReason>;
      children: Array<{
        __typename?: 'PagedRemoteAssist';
        id: string;
        parentRelation?: Maybe<RemoteAssistRelation>;
        createdAt: string;
        assignedAt?: Maybe<string>;
        status: RemoteAssistStatus;
        canceledReason?: Maybe<RemoteAssistCanceledReason>;
        scheduled?: Maybe<{
          __typename?: 'Scheduled';
          promisedWindow: { __typename?: 'PromisedWindow'; start: string };
        }>;
      }>;
      scheduled?: Maybe<{
        __typename?: 'Scheduled';
        promisedWindow: { __typename?: 'PromisedWindow'; start: string };
      }>;
    }>;
    children: Array<{
      __typename?: 'PagedRemoteAssist';
      id: string;
      parentRelation?: Maybe<RemoteAssistRelation>;
      createdAt: string;
      assignedAt?: Maybe<string>;
      status: RemoteAssistStatus;
      canceledReason?: Maybe<RemoteAssistCanceledReason>;
      scheduled?: Maybe<{
        __typename?: 'Scheduled';
        promisedWindow: { __typename?: 'PromisedWindow'; start: string };
      }>;
    }>;
  }>;
};

export type RemoteAssistsQueryVariables = Exact<{
  input: RemoteAssistsInput;
}>;

export type RemoteAssistsQuery = {
  __typename?: 'Query';
  remoteAssists?: Maybe<{
    __typename?: 'PagedRemoteAssists';
    page: number;
    perPage: number;
    totalCount: number;
    data: Array<{
      __typename?: 'PagedRemoteAssist';
      id: string;
      reference?: Maybe<string>;
      affiliateKey?: Maybe<string>;
      canceledReason?: Maybe<RemoteAssistCanceledReason>;
      clientId: string;
      demandClientId: string;
      tradeId: string;
      type: RemoteAssistType;
      status: RemoteAssistStatus;
      createdAt: string;
      assignedAt?: Maybe<string>;
      lastStatusChangeAt: string;
      portalStatus?: Maybe<RemoteAssistPortalStatus>;
      reviewed: boolean;
      customer: {
        __typename?: 'RemoteAssistCustomer';
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        postalCode?: Maybe<string>;
        notes?: Maybe<string>;
        email?: Maybe<{ __typename?: 'Email'; address: string }>;
        phone?: Maybe<{ __typename?: 'Phone'; number: string }>;
      };
      technician?: Maybe<{ __typename?: 'RemoteAssistTechnician'; id: string }>;
      scheduled?: Maybe<{
        __typename?: 'Scheduled';
        promisedWindow: {
          __typename?: 'PromisedWindow';
          start: string;
          end: string;
        };
      }>;
      eligibleSupply?: Maybe<{
        __typename?: 'RemoteAssistEligibleSupply';
        technicianIds: Array<string>;
      }>;
    }>;
  }>;
};

export type ServiceCodesQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceCodesQuery = {
  __typename?: 'Query';
  serviceCodes: {
    __typename?: 'PagedServiceCodes';
    data: Array<{ __typename?: 'ServiceCode'; id: string; name: string }>;
  };
};

export type TechnicianQueryVariables = Exact<{ [key: string]: never }>;

export type TechnicianQuery = {
  __typename?: 'Query';
  technician?: Maybe<{
    __typename?: 'Technician';
    id: string;
    name: string;
    status: TechnicianStatus;
    channelStatuses: { __typename?: 'Channels'; video: VideoStatus };
    email: { __typename?: 'Email'; address: string };
  }>;
};

export type TechniciansQueryVariables = Exact<{
  input?: Maybe<TechniciansInput>;
}>;

export type TechniciansQuery = {
  __typename?: 'Query';
  technicians: Array<{ __typename?: 'Technician'; id: string; name: string }>;
};

export type TechniciansForTeamQueryVariables = Exact<{ [key: string]: never }>;

export type TechniciansForTeamQuery = {
  __typename?: 'Query';
  technicians: Array<{
    __typename?: 'Technician';
    id: string;
    name: string;
    channelStatuses: { __typename?: 'Channels'; video: VideoStatus };
  }>;
};

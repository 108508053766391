import { useQuery } from '@apollo/client';
import { DeveloperApiClientTypes } from '@hpx-it/developer-api-client';
import { REMOTE_ASSISTS_QUERY, TechnicianApiContext } from 'api';
import { usePolling } from 'hooks/usePolling';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  RemoteAssistStatus,
  RemoteAssistsQuery,
  RemoteAssistsQueryVariables,
} from 'types';
import { UserRole } from 'utils';

import { UserContext } from './UserContext';

type TechnicianContextProps = {
  technician?: DeveloperApiClientTypes.Technician;
  setTechnician: Dispatch<
    SetStateAction<DeveloperApiClientTypes.Technician | undefined>
  >;
  currentRemoteAssist?: NonNullable<
    RemoteAssistsQuery['remoteAssists']
  >['data'][number];
  loading: boolean;
};

type TechnicianProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: TechnicianContextProps = {
  setTechnician: () => {},
  loading: false,
};

export const TechnicianContext =
  createContext<TechnicianContextProps>(DEFAULT_CONTEXT);

export const TechnicianProvider = ({ children }: TechnicianProviderProps) => {
  const { isUserAuthenticated, userRoles, userLoading, technicianId } =
    useContext(UserContext);

  const { getTechnician } = useContext(TechnicianApiContext);

  const [technician, setTechnician] =
    usePolling<DeveloperApiClientTypes.Technician>(
      () => getTechnician({ id: technicianId }),
      {
        delay: 30000,
        skip:
          !isUserAuthenticated ||
          !userRoles.includes(UserRole.Technician) ||
          !technicianId,
      },
    );

  const { data: { remoteAssists: currentRemoteAssists = null } = {}, refetch } =
    useQuery<RemoteAssistsQuery, RemoteAssistsQueryVariables>(
      REMOTE_ASSISTS_QUERY,
      {
        skip: !technician?.id,
        variables: {
          input: {
            technicianId: technician?.id,
            status: [RemoteAssistStatus.ASSIGNED],
          },
        },
      },
    );

  const currentRemoteAssist = useMemo(
    () => currentRemoteAssists?.data?.[0] ?? undefined,
    [currentRemoteAssists],
  );

  useEffect(() => {
    if (
      technician?.getVideoChannelStatus() ===
        DeveloperApiClientTypes.VideoStatus.OnCall &&
      !currentRemoteAssist
    ) {
      refetch();
    }
  }, [currentRemoteAssist, refetch, technician]);

  return (
    <TechnicianContext.Provider
      value={{
        technician,
        setTechnician,
        currentRemoteAssist,
        loading: userLoading,
      }}
    >
      {children}
    </TechnicianContext.Provider>
  );
};

import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type SignedLinksApiContextProps = {
  getResidentAppLink: DeveloperApiClient['getRemoteAssistsSignedLink'];
};

export type SignedLinksApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: SignedLinksApiContextProps = {
  getResidentAppLink: () => throwDeveloperApiUnauthenticatedError(),
};

export const SignedLinksApiContext =
  createContext<SignedLinksApiContextProps>(DEFAULT_CONTEXT);

export const SignedLinksApiProvider = ({
  children,
}: SignedLinksApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getResidentAppLink = useCallback(
    async (input: DeveloperApiClientTypes.RemoteAssistsSignedLinkInput) =>
      await getDeveloperApiClient().getRemoteAssistsSignedLink(input),
    [getDeveloperApiClient],
  );

  return (
    <SignedLinksApiContext.Provider value={{ getResidentAppLink }}>
      {children}
    </SignedLinksApiContext.Provider>
  );
};

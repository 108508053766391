import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CSSProperties } from 'react';

export function CopyIcon(
  text: string,
  buttonSize: 'small' | 'medium' | 'large' = 'medium',
  style: CSSProperties = {},
) {
  return (
    <IconButton
      size="small"
      onClick={() => {
        navigator.clipboard.writeText(text ?? '');
      }}
      style={style}
    >
      <ContentCopy fontSize={buttonSize} />
    </IconButton>
  );
}

import { Build, ContentCopy, HdrAuto, Numbers } from '@mui/icons-material';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

type ExtractedEquipmentFieldProps = {
  name: string;
  value: string;
  setValue: (value: string) => void;
  icon: any;
  label: string;
  chipColor: string;
};

const ExtractedEquipmentField = ({
  name,
  value,
  setValue,
  icon,
  label,
  chipColor,
}: ExtractedEquipmentFieldProps) => {
  return (
    <Grid item xs={12} md={12} pb={2}>
      <TextField
        name={name}
        value={value}
        variant="outlined"
        fullWidth
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <>
              <Chip
                label={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {icon}

                    <Typography
                      sx={{ fontWeight: 500, fontSize: '14px', ml: 1 }}
                    >
                      {label}
                    </Typography>
                  </Box>
                }
                sx={{
                  borderRadius: 1,
                  width: '18rem',
                  bgcolor: chipColor,
                }}
              />
              <IconButton
                size="medium"
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              >
                <ContentCopy fontSize="medium" />
              </IconButton>
            </>
          ),
        }}
      />
    </Grid>
  );
};

type ExtractedDataProps = {
  modelNumber: string;
  setModelNumber: Dispatch<SetStateAction<string>>;
  serialNumber: string;
  setSerialNumber: Dispatch<SetStateAction<string>>;
  logo: string;
  setLogo: Dispatch<SetStateAction<string>>;
};

export const ExtractedData = ({
  modelNumber,
  setModelNumber,
  serialNumber,
  setSerialNumber,
  logo,
  setLogo,
}: ExtractedDataProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      alignItems="center"
      display="flex"
      justifyContent="center"
      border={`1px solid ${theme.palette.grey[400]}`}
      margin="1rem"
      paddingRight="1rem"
    >
      {/* Found Model Number and Serial Number Section */}
      {modelNumber && (
        <ExtractedEquipmentField
          name="modelNumber"
          value={modelNumber}
          setValue={setModelNumber}
          icon={
            <Build
              sx={{ color: theme.palette.secondary.main }}
              fontSize="small"
            />
          }
          label="Model No."
          chipColor="#E5FAF8"
        />
      )}
      {serialNumber && (
        <ExtractedEquipmentField
          name="serialNumber"
          value={serialNumber}
          setValue={setSerialNumber}
          icon={
            <Numbers
              sx={{ color: theme.palette.primary.main }}
              fontSize="small"
            />
          }
          label="Serial No."
          chipColor="#E3F1FD"
        />
      )}
      {logo && (
        <ExtractedEquipmentField
          name="brand"
          value={logo}
          setValue={setLogo}
          icon={
            <HdrAuto
              sx={{ color: theme.palette.secondary.main }}
              fontSize="small"
            />
          }
          label="Brand"
          chipColor="#E5FAF8"
        />
      )}
    </Grid>
  );
};

import { Grid } from '@mui/material';
import { CSSProperties, ComponentProps } from 'react';

export const carouselItemStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const carouselImageStyle: CSSProperties = {
  maxHeight: '100%',
  maxWidth: '100%',
  cursor: 'zoom-in',
};

export const carouselVideoStyle: CSSProperties = {
  maxHeight: '100%',
  maxWidth: '100%',
  cursor: 'zoom-in',
};

export const carouselImageDialogPaperStyle: CSSProperties = {
  height: '80%',
};

export const carouselImageDialogIconButtonStyle: CSSProperties = {
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'black',
  zIndex: 10,
};

export const carouselImageDialogContentStyle: CSSProperties = {
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const carouselImageDialogImageStyle: CSSProperties = {
  maxHeight: '100%',
  maxWidth: '100%',
};

export const videoContainerStyle: CSSProperties = {
  position: 'relative',
  display: 'contents',
};

export const playIconStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '3rem',
  color: 'black',
  backgroundColor: 'white',
  borderRadius: '5rem',
  pointerEvents: 'none', // Prevent clicks on the icon from interfering with the video click
  zIndex: 1, // Make sure the icon is above the video
};

export const getLoadingGridContainerStyle = (): ComponentProps<
  typeof Grid
> => ({
  item: true,
  container: true,
  xs: true,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TicketImageCarouselDialogStyle: CSSProperties = {
  width: '100%',
  height: '100%',
};

export const TicketAttachmentCarouselDialogDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

export const TicketAttachmentCarouselDialogDivVideoStyle: CSSProperties = {
  maxWidth: '90%',
  maxHeight: '90%',
  objectFit: 'contain',
};

export const TicketAttachmentCarouselDialogDivImgStyle: CSSProperties = {
  objectFit: 'contain',
};

export const TicketCarouselButtonDivStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

export const TicketCarouselButtonStyle: CSSProperties = {
  padding: '1rem',
  fontSize: '1.25rem',
  margin: '1rem',
};

export const TicketAttachmentCarouseDialoglIFrameStyle: CSSProperties = {
  width: '90%',
  height: '100%',
};

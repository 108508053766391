import { Schedule } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';

export const getLoadingGridContainerStyle = (): ComponentProps<
  typeof Grid
> => ({
  item: true,
  container: true,
  xs: true,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const getDescriptionTitleStyle = (): ComponentProps<
  typeof Typography
> => ({
  variant: 'caption',
  paddingBottom: '5px',
});

export const getInlineDescriptionTitleStyle = (): ComponentProps<
  typeof Typography
> => ({
  ...getDescriptionTitleStyle(),
  display: 'inline',
});

export const getDescriptionStyle = (): ComponentProps<typeof Typography> => ({
  variant: 'body2',
});

export const getInlineDescriptionStyle = (): ComponentProps<
  typeof Typography
> => ({
  ...getDescriptionStyle(),
  variant: 'caption',
  display: 'inline',
  fontWeight: 'bold',
});

export const getScheduleGridStyle = (): ComponentProps<typeof Grid> => ({
  xs: 12,
  sx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const getScheduleStyle = (): ComponentProps<typeof Schedule> => ({
  sx: {
    fontSize: '24px',
    color: 'grey',
    fontWeight: 'bold',
    mb: 1,
  },
});

export const getTimeslotStyle = (): ComponentProps<typeof Typography> => ({
  variant: 'body2',
  fontWeight: 'bold',
});

export const getTimeslotGridStyle = (): ComponentProps<typeof Grid> => ({
  xs: 3,
  sx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const getImagesTitleStyle = (): ComponentProps<typeof Typography> => ({
  variant: 'body2',
  fontWeight: 600,
});

export const getTicketDescriptionGridContainerStyle = (): ComponentProps<
  typeof Grid
> => ({
  container: true,
  direction: 'column',
  width: '100%',
  spacing: 1,
});

export const getTicketDescriptionItemGridStyle = (): ComponentProps<
  typeof Grid
> => ({
  item: true,
});

export const getDescriptionBoxStyle = (): ComponentProps<typeof Box> => ({
  bgcolor: 'grey.300',
  p: 1,
});

import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { Label } from 'components';
import React from 'react';

type EquipmentFieldProps = {
  label: string;
  value: string;
};

const EquipmentField = ({ label, value }: EquipmentFieldProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" pb={1.5}>
      <Label label={label} percentageWidth={25} />
      <Typography
        variant="body2"
        sx={{
          paddingLeft: '1rem',
          whiteSpace: 'pre-line',
        }}
      >
        {value ?? ''}
      </Typography>
    </Box>
  );
};

type handleCreateEquipmentParams = {
  modelNumber?: string;
  serialNumber?: string;
  logo?: string;
};

type CreateEquipmentCardProps = {
  modelNumber: string;
  serialNumber: string;
  logo: string;
  handleCreateEquipment: ({
    modelNumber,
    serialNumber,
    logo,
  }: handleCreateEquipmentParams) => void;
};

export const CreateEquipmentCard = ({
  modelNumber,
  serialNumber,
  logo,
  handleCreateEquipment,
}: CreateEquipmentCardProps) => {
  const theme = useTheme();

  return (
    <>
      {/* Start Create Equipment Card */}
      <Grid item container xs={12} paddingRight="1rem">
        {/* Start Header */}
        <Grid
          item
          xs={12}
          borderRadius="0.25rem 0.25rem 0 0"
          border={`1px solid ${theme.palette.grey[300]}`}
          borderBottom="0"
          sx={{
            padding: '0.5rem 1rem 0.5rem 1rem',
            bgcolor: '#F5FFFD',
          }}
          justifyContent="space-between"
          display="flex"
        >
          <Grid>
            <Typography
              variant="subtitle2"
              fontWeight={500}
              color={theme.palette.grey[800]}
            >
              New Equipment
            </Typography>
          </Grid>
        </Grid>
        {/* End Header */}

        <Grid
          item
          spacing={2}
          xs={12}
          bgcolor="white"
          borderRadius="0 0 0.25rem 0.25rem "
          border={`1px solid ${theme.palette.grey[300]}`}
          sx={{
            padding: '1rem 1.5rem',
            bgcolor: '#F5FFFD',
          }}
        >
          <EquipmentField label="Model #" value={modelNumber} />
          <EquipmentField label="Serial #" value={serialNumber} />
          <EquipmentField label="Brand" value={logo} />
          <Box
            display="flex"
            flexDirection="row-reverse"
            alignItems="flex-end"
            pb={1}
            pt="1.5rem"
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: '100px',
                backgroundColor: theme.palette.secondary.main,
                textTransform: 'capitalize',
              }}
              onClick={() => {
                handleCreateEquipment({
                  modelNumber: modelNumber ?? '',
                  serialNumber: serialNumber ?? '',
                  logo: logo ?? '',
                });
              }}
            >
              Create Equipment
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* End Create Equipment Card */}
    </>
  );
};

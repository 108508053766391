import { useAuth, useAuthActions, useLoginWithRedirect } from '@frontegg/react';
import { client } from 'api';
import { useLocalStorage, useURLVariable } from 'hooks';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole, setAuthToken } from 'utils';

type UserContextProps = {
  isUserAuthenticated: boolean;
  token: string | null;
  userId: string;
  userLoading: boolean;
  userClientId: string;
  userPossibleClientIds: string[];
  userName: string;
  userRoles: UserRole[];
  switchClient: (clientId: string) => Promise<void>;
  technicianId: string;
};

type UserProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: UserContextProps = {
  isUserAuthenticated: false,
  token: null,
  userLoading: false,
  userId: '',
  userClientId: '',
  userPossibleClientIds: [],
  userName: '',
  userRoles: [],
  switchClient: async () => {},
  technicianId: '',
};

export const UserContext = createContext<UserContextProps>(DEFAULT_CONTEXT);

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user, isAuthenticated } = useAuth();
  const { switchTenant } = useAuthActions();
  const [token, setToken] = useState<string | null>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userId, setUserId] = useState<string>('');
  const [technicianId, setTechnicianId] = useState<string>('');
  const [userClientId, setUserClientId] = useState<string>('');
  const [userPossibleClientIds, setUserPossibleClientIds] = useState<string[]>(
    [],
  );
  const [userName, setUserName] = useState<string>('');
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const loginWithRedirect = useLoginWithRedirect();
  const [switchToClientId, setSwitchToClientId] = useURLVariable(
    'switchToClientId',
    null,
  );
  const [loginRedirectPath, setLoginRedirectPath] = useLocalStorage<
    string | null
  >('login-redirect-path', null);

  const switchClient = useCallback(
    async (clientId: string) => {
      // clear apollo client cache as user client changed
      await client.clearStore();
      switchTenant({
        tenantId: clientId,
      });
    },
    [switchTenant],
  );

  useEffect(() => {
    if (user) {
      setToken(user.accessToken);
      setAuthToken(user.accessToken);
      setUserId(user.id);
      setUserClientId(user.tenantId ?? '');
      setUserPossibleClientIds(user.tenantIds);
      setUserName(user.name);
      setUserRoles(
        user.roles.map(
          (role: { key: string }) =>
            UserRole[role.key as keyof typeof UserRole],
        ),
      );

      const parsedMetadata = JSON.parse(user.metadata);
      setTechnicianId(
        parsedMetadata?.technician &&
          (Array.isArray(parsedMetadata.technician)
            ? parsedMetadata.technician.find(
                (tech: { client_id: string; id: string }) =>
                  tech.client_id === user.tenantId,
              )?.id
            : parsedMetadata.technician.id),
      );

      setUserLoading(false);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      // if authenticated, redirect
      if (loginRedirectPath) {
        navigate(loginRedirectPath);
        setLoginRedirectPath(null, { debounce: false });
      }
    } else {
      // redirect to login if unauthenticated and needed
      setLoginRedirectPath(`${location.pathname}${location.search}`, {
        debounce: false,
      });
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isAuthenticated, loginWithRedirect, setLoginRedirectPath]);

  useEffect(() => {
    if (userClientId && switchToClientId) {
      setSwitchToClientId(null);
      if (switchToClientId !== userClientId) {
        switchClient(switchToClientId);
      }
    }
  }, [userClientId, setSwitchToClientId, switchClient, switchToClientId]);

  return (
    <UserContext.Provider
      value={{
        isUserAuthenticated: isAuthenticated && !userLoading,
        token,
        userLoading,
        userId,
        userClientId,
        userPossibleClientIds,
        userName,
        userRoles,
        technicianId,
        switchClient,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import { Ticket } from '@hpx-it/queue-client';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, CircularProgress, Grid } from '@mui/material';
import { AttachmentApiContext } from 'api';
import { QueueDashboardContext } from 'pages/technician-queue-dashboard/contexts';
import {
  AttachmentUrl,
  MediaType,
} from 'pages/technician-queue-dashboard/types';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getLoadingGridContainerStyle } from './style';
import { TicketAttachmentCarousel } from './TicketImageCarousel/TicketCarousel';
import { TicketImageCarouselDialog } from './TicketImageCarousel/TicketCarouselDialog';

export const videoFiles = ['.mp4', '.mov', 'avi'];
export const imageFiles = ['.png', '.jpg', '.jpeg', '.gif', '.webp'];

export function getMediaType(fileExtension: string): MediaType {
  if (videoFiles.includes(fileExtension)) {
    return 'video';
  }
  if (imageFiles.includes(fileExtension)) {
    return 'image';
  }
  if ('.pdf' === fileExtension) {
    return 'pdf';
  }
  return undefined;
}

export const TicketAttachments = ({ ticket }: { ticket: Ticket }) => {
  const { getAttachmentUrls, getAttachments } =
    useContext(AttachmentApiContext);
  const { selectedImage, setSelectedImage } = useContext(QueueDashboardContext);

  const [attachmentUrls, setAttachmentUrls] = useState<AttachmentUrl[]>([]);
  const [attachmentUrlsLoading, setAttachmentUrlsLoading] = useState(true);

  const getAttachmentIds = useCallback(async () => {
    return await getAttachments({
      attached_to_type: 'remote_assist',
      attached_to: ticket.getModel().remote_assist_id,
      page: 1,
    });
  }, [getAttachments, ticket]);

  const getAndSetUrlsWithoutLoading = useCallback(async () => {
    const remoteAssistAttachments = await getAttachmentIds();

    let attachments: AttachmentUrl[] = [];
    if (remoteAssistAttachments.data.length > 0) {
      const attachmentExtensions: Record<string, string> =
        remoteAssistAttachments.data.reduce((acc, attachment) => {
          acc[attachment.id] = attachment.file_extension;
          return acc;
        }, {} as Record<string, string>);

      // Fetch the URLs
      const { attachment_urls } = await getAttachmentUrls({
        attachment_id: remoteAssistAttachments.data.map(
          (attachment) => attachment.id,
        ),
      });

      // Combine URLs with their corresponding file extensions
      attachments = attachment_urls
        .map((attachmentUrl) => ({
          ...attachmentUrl,
          mediaType: getMediaType(attachmentExtensions[attachmentUrl.id]),
          extension: attachmentExtensions[attachmentUrl.id],
        }))
        .filter((attachment) => attachment.mediaType !== undefined);
    }

    setAttachmentUrls(attachments);
  }, [getAttachmentIds, getAttachmentUrls]);

  const getAndSetUrls = useCallback(async () => {
    setAttachmentUrlsLoading(true);
    try {
      await getAndSetUrlsWithoutLoading();
    } finally {
      setAttachmentUrlsLoading(false);
    }
  }, [getAndSetUrlsWithoutLoading]);

  useEffect(() => {
    getAndSetUrls();
  }, [getAndSetUrls]);

  if (attachmentUrlsLoading) {
    return (
      <Grid {...getLoadingGridContainerStyle()}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid item>
        {Object.keys(attachmentUrls).length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <ImageNotSupportedIcon
              sx={(theme) => ({
                fontSize: '10rem',
                color: theme.palette.grey[400],
              })}
            />
          </Box>
        ) : (
          <>
            <TicketAttachmentCarousel
              attachmentUrls={attachmentUrls}
              onAttachmentClick={(attachment: AttachmentUrl) => {
                setSelectedImage(attachment);
              }}
            />
            {selectedImage && (
              <TicketImageCarouselDialog
                attachmentUrls={attachmentUrls}
                selectedImage={selectedImage}
                remoteAssistId={ticket.model.remote_assist_id}
                onClose={() => setSelectedImage(undefined)}
                setAttachmentUrls={setAttachmentUrls}
                setSelectedImage={setSelectedImage}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

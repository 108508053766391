import {
  DeveloperApiClient,
  DeveloperApiClientTypes,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type TechnicianApiContextProps = {
  getTechnicians: DeveloperApiClient['getTechnicians'];
  getTechnician: DeveloperApiClient['getTechnician'];
  updateTechnician: DeveloperApiClient['updateTechnician'];
};

export type TechnicianApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: TechnicianApiContextProps = {
  getTechnicians: () => throwDeveloperApiUnauthenticatedError(),
  getTechnician: () => throwDeveloperApiUnauthenticatedError(),
  updateTechnician: () => throwDeveloperApiUnauthenticatedError(),
};

export const TechnicianApiContext =
  createContext<TechnicianApiContextProps>(DEFAULT_CONTEXT);

export const TechnicianApiProvider = ({
  children,
}: TechnicianApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getTechnicians = useCallback(
    async (input: DeveloperApiClientTypes.GetTechniciansInput) =>
      await getDeveloperApiClient().getTechnicians(input),
    [getDeveloperApiClient],
  );

  const getTechnician = useCallback(
    async (input: DeveloperApiClientTypes.GetTechnicianInput) =>
      await getDeveloperApiClient().getTechnician(input),
    [getDeveloperApiClient],
  );

  const updateTechnician = useCallback(
    async (input: DeveloperApiClientTypes.UpdateTechnicianInput) =>
      await getDeveloperApiClient().updateTechnician(input),
    [getDeveloperApiClient],
  );

  return (
    <TechnicianApiContext.Provider
      value={{ getTechnicians, getTechnician, updateTechnician }}
    >
      {children}
    </TechnicianApiContext.Provider>
  );
};

import { Grid } from '@mui/material';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import { Dispatch, SetStateAction } from 'react';

import { Screenshot } from '.';

export const SCREENSHOTS_CONTAINER_ID = 'screenshots';

type ScreenshotsContainerProps = {
  selectedRA: { id: string };
  screenshotURLs: Record<string, AttachmentUrl>;
  setScreenshotURLs: Dispatch<SetStateAction<Record<string, AttachmentUrl>>>;
  pastRA?: boolean;
};

export const ScreenshotsContainer = ({
  selectedRA,
  screenshotURLs,
  setScreenshotURLs,
  pastRA,
}: ScreenshotsContainerProps): JSX.Element => {
  return (
    <Grid
      item
      container
      id={SCREENSHOTS_CONTAINER_ID}
      sx={{ gap: '1rem', padding: '0rem 1.5rem' }}
      xs={12}
    >
      {Object.entries(screenshotURLs).map(([id, attachmentUrl]) => (
        <Screenshot
          id={id}
          url={attachmentUrl.url}
          mediaType={attachmentUrl.mediaType}
          selectedRA={selectedRA}
          setScreenshotURLs={setScreenshotURLs}
          pastRA={pastRA}
        />
      ))}
    </Grid>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { RemoteAssistApiContext } from 'api/developerApi/remoteAssists';
import { UserContext } from 'contexts';
import { Dispatch, SetStateAction, useContext } from 'react';
import { RemoteAssist, RemoteAssistQuery } from 'types';
import {
  CLIENT_ID_TO_NAME_MAP,
  TRADE_ID_TO_NAME_MAP,
  UserRole,
  formatTimeRange,
} from 'utils';

type CancelAppointmentModalProps = {
  showModal: boolean;
  handleClose?: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  remoteAssist: RemoteAssist | NonNullable<RemoteAssistQuery['remoteAssist']>;
  refetch?: () => Promise<unknown>;
  setAnchor?: (value: React.SetStateAction<HTMLElement | null>) => void;
};

export const CancelAppointmentModal = ({
  showModal,
  handleClose,
  setShowModal,
  remoteAssist,
  refetch,
  setAnchor,
}: CancelAppointmentModalProps) => {
  const theme = useTheme();
  const { userRoles } = useContext(UserContext);
  const { cancelRemoteAssist } = useContext(RemoteAssistApiContext);

  const handleCancelAppointment = async () => {
    try {
      await cancelRemoteAssist({
        id: remoteAssist.id,
        canceled_reason: userRoles.includes(UserRole.Agent)
          ? 'canceled_by_agent'
          : 'canceled',
      });

      if (refetch) {
        refetch();
      }
      setShowModal(false);
      if (setAnchor) {
        setAnchor(null);
      }
      handleClose?.();
    } catch (error) {
      setShowModal(false);
      if (setAnchor) {
        setAnchor(null);
      }
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      PaperProps={{
        style: { borderRadius: '0.5rem' },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem 0.5rem 0.5rem 1.2rem"
        sx={{ bgcolor: theme.palette.primary.main }}
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography color={theme.palette.common.white} variant="h6">
              Confirm Cancellation
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setShowModal(false);
                if (setAnchor) {
                  setAnchor(null);
                }
              }}
            >
              <CloseIcon
                sx={{
                  color: theme.palette.common.white,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid padding="1.25rem">
        <Typography>
          Are you sure you want to permanently cancel the following appointment:
        </Typography>
        <Grid display="flex" padding="1rem 0px 0px 0px">
          <Typography fontWeight={700}>
            {remoteAssist.clientId
              ? CLIENT_ID_TO_NAME_MAP[remoteAssist.clientId] +
                ' • ' +
                TRADE_ID_TO_NAME_MAP[remoteAssist.tradeId]
              : ' '}
          </Typography>
          &nbsp;&nbsp;
          <Typography>
            {remoteAssist.scheduled
              ? formatTimeRange(
                  remoteAssist.scheduled.promisedWindow.start,
                  remoteAssist.scheduled.promisedWindow.end,
                )
              : ''}
          </Typography>
        </Grid>
        <Typography>
          {remoteAssist.customer &&
          remoteAssist.customer.firstName &&
          remoteAssist.customer.lastName
            ? remoteAssist.customer.firstName +
              ' ' +
              remoteAssist.customer.lastName +
              ' ' +
              (remoteAssist.customer.phone
                ? remoteAssist.customer.phone.number
                : 'N/A')
            : ''}
        </Typography>
        <Grid
          item
          container
          spacing={1}
          display="flex"
          flexDirection="row-reverse"
          padding="1.5rem 0 0 0"
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{
                borderRadius: '100px',
                backgroundColor: '#10857A',
              }}
              onClick={() => {
                handleCancelAppointment();
              }}
            >
              Cancel Appointment
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '100px',
                borderColor: '#10857A',
                color: '#10857A',
              }}
              onClick={() => {
                setShowModal(false);
                if (setAnchor) {
                  setAnchor(null);
                }
              }}
            >
              Keep It
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

import { useInterval } from '@hpx-it/react-app';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function usePolling<T>(
  fn: (...args: any) => Promise<T>,
  options: {
    delay: number;
    skip?: boolean;
  },
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const { delay, skip } = options;
  const [data, setData] = useState<T>();

  useEffect(() => {
    (async () => setData(await fn()))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (skip) {
      return;
    }

    try {
      setData(await fn());
    } catch (error) {}
  }, delay);

  return [data, setData];
}

import { gql } from '@apollo/client';

export const REMOTE_ASSISTS_FOR_DASHBOARD_QUERY = gql`
  query remoteAssistForDashboard($input: RemoteAssistsInput!) {
    remoteAssists(input: $input) {
      data {
        clientId
        reference
        supplyClientId
        id
        createdAt
        assignedAt
        customer {
          reference
          firstName
          lastName
          phone {
            number
          }
          postalCode
        }
        technician {
          id
        }
        tradeId
        scheduled {
          promisedWindow {
            start
            end
          }
        }
        portalStatus
        reviewed
      }
      page
      perPage
      totalCount
    }
  }
`;

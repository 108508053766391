import { ReactNode, createContext, useState } from 'react';

import { TicketType } from '../types';

type SelectedColumnContextProps = {
  selectedColumn: TicketType;
  setSelectedColumn: (column: TicketType) => void;
};

export type SelectedColumnProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: SelectedColumnContextProps = {
  selectedColumn: undefined,
  setSelectedColumn: () => {},
};

export const SelectedColumnContext =
  createContext<SelectedColumnContextProps>(DEFAULT_CONTEXT);

export const SelectedColumnProvider = ({
  children,
}: SelectedColumnProviderProps) => {
  const [selectedColumn, setSelectedColumn] = useState<TicketType>(undefined);

  return (
    <SelectedColumnContext.Provider
      value={{
        selectedColumn,
        setSelectedColumn,
      }}
    >
      {children}
    </SelectedColumnContext.Provider>
  );
};

import { ReactNode } from 'react';

import { EquipmentApiProvider } from './equipment';
import { RemoteAssistApiProvider } from './remoteAssists';
import { TradeApiProvider } from './trades';
import {
  AttachmentApiProvider,
  ClientApiProvider,
  CommentsApiProvider,
  DeveloperApiClientProvider,
  LabelApiProvider,
  ProductInfoApiProvider,
  QueueApiProvider,
  SignedLinksApiProvider,
  TechnicianApiProvider,
  VideoCallingApiProvider,
} from '.';

export type DeveloperApiProviderProps = {
  children: ReactNode;
};

export const DeveloperApiProvider = ({
  children,
}: DeveloperApiProviderProps) => {
  return (
    <DeveloperApiClientProvider>
      <AttachmentApiProvider>
        <ClientApiProvider>
          <LabelApiProvider>
            <QueueApiProvider>
              <RemoteAssistApiProvider>
                <TechnicianApiProvider>
                  <TradeApiProvider>
                    <VideoCallingApiProvider>
                      <EquipmentApiProvider>
                        <ProductInfoApiProvider>
                          <CommentsApiProvider>
                            <SignedLinksApiProvider>
                              {children}
                            </SignedLinksApiProvider>
                          </CommentsApiProvider>
                        </ProductInfoApiProvider>
                      </EquipmentApiProvider>
                    </VideoCallingApiProvider>
                  </TradeApiProvider>
                </TechnicianApiProvider>
              </RemoteAssistApiProvider>
            </QueueApiProvider>
          </LabelApiProvider>
        </ClientApiProvider>
      </AttachmentApiProvider>
    </DeveloperApiClientProvider>
  );
};

import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

import { formatTimers } from './formatTimers';
import { getTimerGridStyle, getTimerTypographyStyle } from './style';

type CountUpTimerProps = {
  startTime: string;
};

export function useCountUpTimer({ startTime }: CountUpTimerProps) {
  const {
    minutes,
    hours,
    days,
    reset: raCountUpReset,
  } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    const startTimeinMS = new Date(startTime).getTime();
    const currentTimeInMS = new Date().getTime();
    const timeSinceLastUpdateInS = (currentTimeInMS - startTimeinMS) / 1000;
    const offset = new Date();
    offset.setSeconds(offset.getSeconds() + timeSinceLastUpdateInS);
    raCountUpReset(offset.getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  return formatTimers({ days, hours, minutes });
}

export function CountUpTimer({ startTime }: CountUpTimerProps) {
  const timerString = useCountUpTimer({ startTime });

  return (
    <Grid container {...getTimerGridStyle()}>
      <Grid item>
        <Typography {...getTimerTypographyStyle()}>{timerString}</Typography>
      </Grid>
    </Grid>
  );
}

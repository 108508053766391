import {
  QueueFacadeFactory,
  Ticket,
  TicketFactory,
  queue,
  queueBooleanStrategies,
  queueGetNumberStrategies,
  queueSetStrategies,
  queueStringStrategies,
} from '@hpx-it/queue-client';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { DeveloperApiClientContext } from '..';

type QueueApiContextProps = {
  getQueue: (filter: QueueFilter) => Promise<Ticket[] | undefined>;
  getTicket: (id: string) => Promise<Ticket | undefined>;
  getPinnedTickets: (id: string) => Promise<Ticket[] | undefined>;
  getTicketsOnProperty: (ticket: Ticket) => Promise<Ticket[] | undefined>;
  getPropertyMap: () => Promise<Map<string, number>>;
  getTimeslots: () => Promise<queue.AvailableTimeslotsResponse>;
};

export type QueueApiProviderProps = {
  children: ReactNode;
};

export type QueueFilter =
  | { name: 'none' | 'claimed' | 'unclaimed' }
  | { name: 'claimed_by'; claimedById: string }
  | { name: 'location'; location: string };

const DEFAULT_CONTEXT: QueueApiContextProps = {
  getQueue: async () => undefined,
  getTicket: async () => undefined,
  getPinnedTickets: async (id: string) => undefined,
  getTicketsOnProperty: async () => undefined,
  getPropertyMap: async () => new Map(),
  getTimeslots: async () => ({ timeslots: [], immediate_available: false }),
};

export const QueueApiContext =
  createContext<QueueApiContextProps>(DEFAULT_CONTEXT);

export const QueueApiProvider = ({ children }: QueueApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const queueFacade = useMemo(
    () =>
      new QueueFacadeFactory(
        new TicketFactory({
          isStrategies: queueBooleanStrategies,
          getNumberStrategies: queueGetNumberStrategies,
          setStrategies: queueSetStrategies,
          getStringStrategies: queueStringStrategies,
        }),
      ).getQueueFacade(getDeveloperApiClient(), getDeveloperApiClient()),
    [getDeveloperApiClient],
  );

  const getQueue = useCallback(
    async (filter: QueueFilter) => {
      if (!queueFacade) {
        return undefined;
      }
      switch (filter.name) {
        case 'none':
          return await queueFacade.getCurrentQueue();
        case 'unclaimed':
          return await queueFacade.getCurrentUnclaimedQueue();
        case 'claimed':
          return await queueFacade.getCurrentClaimedQueue();
        case 'claimed_by':
          return await queueFacade.getTicketsClaimedBy(filter.claimedById);
        // TOOD: replace temp location filter
        case 'location':
          return (await queueFacade.getCurrentQueue()).filter(
            (ticket) => ticket.getLocation() === filter.location,
          );
      }
    },
    [queueFacade],
  );

  const getTicket = useCallback(
    async (id: string) => {
      if (!queueFacade) {
        return undefined;
      }
      return await queueFacade.getTicket(id);
    },
    [queueFacade],
  );

  const getPinnedTickets = useCallback(
    async (id: string) => {
      if (!queueFacade) {
        return undefined;
      }

      return await queueFacade.getTicketsClaimedBy(id);
    },
    [queueFacade],
  );

  const getTicketsOnProperty = useCallback(
    async (ticket: Ticket) => {
      if (!queueFacade) {
        return undefined;
      }
      return await queueFacade.getTicketsOnProperty(ticket.getLocation());
    },
    [queueFacade],
  );

  const getPropertyMap = useCallback(async () => {
    return await queueFacade.getPropertiesInQueue();
  }, [queueFacade]);

  const getTimeslots = useCallback(async () => {
    return await getDeveloperApiClient().getTimeslots();
  }, [getDeveloperApiClient]);

  return (
    <QueueApiContext.Provider
      value={{
        getQueue,
        getPinnedTickets,
        getTicket,
        getTicketsOnProperty,
        getPropertyMap,
        getTimeslots,
      }}
    >
      {children}
    </QueueApiContext.Provider>
  );
};

import { Ticket } from '@hpx-it/queue-client';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { CopyIcon } from 'pages/remote-assist-call/component/CopyIcon';

import { useCountDownTimer, useCountUpTimer } from '../../../Timers';
import {
  getDescriptionBoxStyle,
  getDescriptionStyle,
  getDescriptionTitleStyle,
  getInlineDescriptionStyle,
  getInlineDescriptionTitleStyle,
  getTicketDescriptionGridContainerStyle,
  getTicketDescriptionItemGridStyle,
} from './style';

export const TicketDescription = ({ ticket }: { ticket: Ticket }) => {
  return (
    <Grid {...getTicketDescriptionGridContainerStyle()}>
      <Grid {...getTicketDescriptionItemGridStyle()}>
        <Grid container direction="row">
          <Grid item xs>
            <Typography {...getDescriptionStyle()}>
              {`${ticket.getModel().remote_assist?.customer.first_name} ${
                ticket.getModel().remote_assist?.customer.last_name
              }`}
            </Typography>
            <TicketDescriptionQueueDuration ticket={ticket} />
          </Grid>
          <Grid item container width="auto" direction="row">
            <Grid item>
              <Typography {...getDescriptionStyle()}>
                {ticket.getUserViewableId()}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={'Copy Id'}>
                {CopyIcon(ticket.getUserViewableId(), 'small', {
                  marginLeft: '0.5rem',
                  padding: 0,
                })}
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid {...getTicketDescriptionItemGridStyle()}>
        <Typography {...getDescriptionTitleStyle()}>Description</Typography>
        <Box {...getDescriptionBoxStyle()}>
          <Typography {...getDescriptionStyle()}>
            {ticket.getDescription() || 'No description.'}
          </Typography>
        </Box>
      </Grid>
      <Grid {...getTicketDescriptionItemGridStyle()}>
        <Typography {...getDescriptionTitleStyle()}>Location</Typography>
        <Box {...getDescriptionBoxStyle()}>
          <Typography {...getDescriptionStyle()}>
            {ticket.getLocation() || 'No location.'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const TicketDescriptionQueueDuration = ({ ticket }: { ticket: Ticket }) => {
  const [createdAt, timeslotStart, timeslotEnd] = [
    ticket.getCreatedAt(),
    ticket.getTimeslotStart(),
    ticket.getTimeslotEnd(),
  ];

  const createdAtTimer = useCountUpTimer({ startTime: createdAt });

  return (
    <Grid container direction="column">
      {ticket.isWithinTimeslot() && !ticket.hasTerminalRemoteAssistStatus() && (
        <>
          {timeslotStart && !ticket.is('POSTPONED') && (
            <Grid item>
              <TimeslotStartTimer timeslotStart={timeslotStart} />
            </Grid>
          )}
          {timeslotEnd && (
            <Grid item>
              <TimeslotEndTimer timeslotEnd={timeslotEnd} />
            </Grid>
          )}
        </>
      )}
      {!ticket.hasTimeslotStarted() &&
        !ticket.hasTerminalRemoteAssistStatus() &&
        timeslotStart && (
          <Grid item>
            <TimeslotStartsInTimer timeslotStart={timeslotStart} />
          </Grid>
        )}
      <Grid item>
        <Typography {...getInlineDescriptionTitleStyle()}>
          {'Ticket created '}
        </Typography>
        <Typography {...getInlineDescriptionStyle()}>
          {createdAtTimer}
        </Typography>
        <Typography {...getInlineDescriptionTitleStyle()}>{' ago'}</Typography>
      </Grid>
    </Grid>
  );
};

const TimeslotStartTimer = ({ timeslotStart }: { timeslotStart: string }) => {
  const timeslotStartTimer = useCountUpTimer({ startTime: timeslotStart });

  return (
    <>
      <Typography {...getInlineDescriptionTitleStyle()}>
        {'In timeslot for '}
      </Typography>
      <Typography {...getInlineDescriptionStyle()}>
        {timeslotStartTimer}
      </Typography>
    </>
  );
};

const TimeslotEndTimer = ({ timeslotEnd }: { timeslotEnd: string }) => {
  const timeslotEndTimer = useCountDownTimer({ endTime: timeslotEnd });
  return (
    <>
      <Typography {...getInlineDescriptionTitleStyle()}>
        {'Timeslot ends in '}
      </Typography>
      <Typography {...getInlineDescriptionStyle()}>
        {timeslotEndTimer}
      </Typography>
    </>
  );
};

const TimeslotStartsInTimer = ({
  timeslotStart,
}: {
  timeslotStart: string;
}) => {
  const timeslotEndTimer = useCountDownTimer({ endTime: timeslotStart });
  return (
    <>
      <Typography {...getInlineDescriptionTitleStyle()}>
        {'Timeslot starts in '}
      </Typography>
      <Typography {...getInlineDescriptionStyle()}>
        {timeslotEndTimer}
      </Typography>
    </>
  );
};
